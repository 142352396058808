.processing_error_block {
    width: 100%;
    background-color: #fff1f1;
    border-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.processing_error_icon {
    color: #000000;
}

#processing_error_text {
    font-size: 1.6rem;
    padding: 5px;
    text-align: center;
}

.error_block {
    width: 100%;
    background-color: #fff1f1;
    border-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 650px;
}

.error_getting_hosts {
    width: 100%;
    background-color: #fff1f1;
    border-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.host_header_tooltip {
    font-size: 12px !important;
    margin-top: 2px !important;
}

.host_body_tooltip {
    font-size: 11px !important;
    margin-top: 1px !important;
}

.host_divider_tooltip {
    margin: 2px 0 !important;
}
