.content {
  display: flex;
  flex-direction: column;
}

.fields {
  width: 100%;
}

.card_header {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}

.legend_container {
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
}

.legend_container p {
  margin: 0;
}

.legend_line {
  width: 350px;
  gap: 5px;
  display: flex;
  height: 30px;
}

.legend_color {
  width: 20px;
  height: 20px;
}

.paper_style {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  padding: 16px;
}

.analytic_card_donut {
  position: absolute;
  text-align: center;
}

.analytic_card_visit_number {
  font-size: 75px;
}

.percentage {
  font-size: 20px;
}

.resultDonut {
  width: 100%;
  max-width: 540px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.container {
  width: 100%;
  margin-top: 8px;
  flex-grow: 1;
}

.graph {
  height: 99%;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.doughnut {
  display: flex;
  width: 100%;
  position: relative;
}

.card_header {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}

.grid_item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
